<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="On Duty Exception" slot="title" link="/helpContent/addOnDutyException" />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-3">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="form.staffId"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <inputDate
            label="Start Date"
            v-model="form.startDate"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputTime
            label="Start Time"
            v-model="form.startTime"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <!-- <inputDate label="End Date" v-model="form.endDate" rules="required" /> -->
           <!-- sagar 05-09-2023 changes next date disable -->
           <InputDate label="End Date"   rules="required" :options="options" v-model="form.endDate" />
        </div>
        <div class="col-sm-3">
          <InputTime label="End Time" v-model="form.endTime" rules="required" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-4">
          <InputTextArea
            label="Reason"
            v-model="form.reason"
            rules="required"
          />
        </div>
        <div class="col-sm-4">
          <InputTextArea
            label="Evidence"
            v-model="form.evidence"
            rules="required"
          />
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>

    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
      <FormButton
        
        @click="()=>{$router.go(-1)}"
          >Ok</FormButton
        ></div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { addODException, getAllStaffByHRorManager, getODExceptionById } from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    InputDate,
    FormWrapper,
    PageTitle,
    ValidationObserver,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      form: {
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        reason: "",
        evidence: "",
        staffId: null,
        id:0,
      },
      staffList: [],
      options: {
disabledDate(date) {
  return date && date.valueOf() > Date.now();
  }
},
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},

  created() {
    this.getLeaveRecord();
  },
  methods: {
    sendData() {
      this.showMessage.isVisible = false;
      this.$refs.validator.validate().then((result) => {
        if (result) {
          const data = {
            startDate: this.form.startDate,
            endDate: this.form.endDate,
            startTime: this.form.startTime,
            endTime: this.form.endTime,
            evidence: this.form.evidence,
            reason: this.form.reason,
            staffId: this.form.staffId,
          };
          addODException(data).then((res) => {
            this.form = {};
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          });
        }
      });
    },
    getLeaveRecord() {
      getAllStaffByHRorManager().then((res) => {
        this.staffList = res.data;
        this.form.staffId = this.userData.user_id;
      });
      if (this.$route.params.id) {
        const data={
            id:parseInt(atob(this.$route.params.id))
        }
        getODExceptionById(data).then((res) => {
          this.form.startDate=res.data[0].OD_StartDate;
            this.form.endDate=res.data[0].OD_EndDate;
           this.form.startTime=res.data[0].startTime;
        this.form.endTime=res.data[0].EndTime;
           this.form.evidence=res.data[0].Evidence;
           this.form.reason=res.data[0].Notes;
           this.form.staffId=res.data[0].Staff_id;
           this.form.id=res.data[0].parseInt(atob(this.$route.params.id));

      });
      }
    },
  },
};
</script>
